import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName } from "../helpers";
import { identity } from "deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Repeat, DollarSign, List, Shield, Video, Link as LinkIcon, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"

export const Home = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  



  return (
    <div>
    {(!isLoading) && (
      <>
      <div className="h-[calc(100vh-20rem)] w-screen grid place-items-center">
        <a href="https://diamondapp.com/u/NeoDeso">
          <Button size="lg">Get your claim on</Button>
        </a>
      </div>


      </>
    )}

     
    </div>
  );
}
